<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">View & edit timesheets</h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between">
                  <h2 class="card-title ">
                    <button class="button-1 w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Add Time </button>
                  </h2>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">New Timesheet</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <form ref="ProjectForm" @submit.prevent="PostAddTimeSheet()" method="post" class="row" align="left">
                            <div class="col-md-12">
                              <label for="project" class="form-label">Project</label>
                              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                                <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                                  <Multiselect v-model="MultiSelectProject.value" v-bind="MultiSelectProject" ref="multiselect" class="size-multiselect"></Multiselect>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-3">
                              <label for="project" class="form-label">From</label>
                              <v-date-picker  :change="ActiveToInput()" v-model="TimeSheetPost.from" :max-date="TimePicker.date" :timezone="TimePicker.timezone" mode="DateTime" is24hr :minute-increment="10" :model-config="modelConfig" >
                                <template  v-slot="{ inputValue, inputEvents }">
                                  <input class="form-control" :value="inputValue" v-on="inputEvents"  />
                                </template>
                              </v-date-picker>
                            </div>
                            <div class="col-md-6 mt-3">
                              <label for="project" class="form-label">To</label>
                              <v-date-picker v-model="TimeSheetPost.to" :min-date="TimeSheetPost.from" :max-date="TimeSheetPost.from" :timezone="TimePicker.timezone" mode="DateTime" is24hr :minute-increment="10" :model-config="modelConfig" >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input :disabled="Form.TimeSheetTo"  class="form-control" :value="inputValue" v-on="inputEvents" />
                                </template>
                              </v-date-picker>
                            </div>
                            <div class="mb-3 mt-3">
                              <label for="project" class="form-label">Add Reason</label>
                              <textarea v-model="TimeSheetPost.reason" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="col-md-12 mt-3"></div>
                            <div class="modal-footer">
                              <button type="submit" :disabled="Form.submit" class="button-1 btn btn-primary">Save changes</button>
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabla-->
              <div class="">
                <table id="timesheet-table" class="table table-striped  responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Project</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Time</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Date</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Status</span>
                      </th>
                       <th scope="col" class="thead-bottom">
                        <span class="text-blue">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special" v-if="ListTimeSheets.status != 'false'">
                    <tr class="text-start" v-for="(obj, key, index) in ListTimeSheets.list" :key="index">
                      <td class="text-start">{{obj.project}}</td>
                      <td class="text-start">{{ $filters.UnixToDateHours(obj.init) }} - {{ $filters.UnixToDateHours(obj.finaly) }}</td>
                      <td class="text-start">{{obj.date}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Approved</p>
                        <p v-if="obj.status == '0'" class="special-badget-status-inactive">Not approved</p>
                      </td>
                       <td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                            <li>
                              <a class="dropdown-item" v-on:click="DeleteTimeSheet(obj.id)">Delete timesheet</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import Multiselect from '@vueform/multiselect'
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'Project',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      TimePicker:
      {
        date: new Date(),
        timezone: '',
        max_time:''
      },
      modelConfig: 
      {
        //type: 'string',
        timeAdjust: '00:00:00',
        //mask: 'hh:mm', 
      },
      TimeSheetPost: 
      {
        date: "",
        from: "",
        to: "",
        reason: "",
        project: ""
      },
      ListTimeSheets: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false,
        TimeSheetTo: true
      },
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      }
    }),
    methods: 
    {
      PostAddTimeSheet() 
      {
        const PostForm = 
        {
          date: "",
          from: "",
          to: "",
          reason: "",
          project: ""
        }

        this.Form.submit = true
        let from = new Date(this.TimeSheetPost.from);
        PostForm.from  = Math.floor(from.getTime() / 1000);
        let to = new Date(this.TimeSheetPost.to);
        PostForm.to  = Math.floor(to.getTime() / 1000);

        PostForm.project = this.MultiSelectProject.value
        PostForm.reason = this.TimeSheetPost.reason

        Api.post("/timesheet/register", PostForm).then((result) => 
        {
          if(result.data.status == "true")
          {
            $('#timesheet-table').DataTable().destroy()

            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            this.$nextTick(function() 
            {
              setTimeout( () => 
              {
                this.MultiSelectProject.value = []
                this.TimeSheetPost.from = ""
                this.TimeSheetPost.to= ""
                this.TimeSheetPost.reason = ""
                this.Form.submit = false
              }, 5000);
                
              this.GetTimeSheet()
            })
          }
        
          if(result.data.status == "false")
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })

            setTimeout( () => 
            {
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetTimeSheet() 
      {
        Api.post("/timesheet/list").then((result) => 
        {
          this.ListTimeSheets.status = result.data.status

          if(this.ListTimeSheets.status == "true")
          {
            this.ListTimeSheets.list = result.data.timesheets;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#timesheet-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/timesheet/list").then((result) => 
        {
          this.ListTimeSheets.status = result.data.status

          if(this.ListTimeSheets.status == "true")
          {
            this.ListTimeSheets.list = result.data.timesheets;
          }
        })
      },
      GetMemberProjects()
      {
        Api.get('/projects-members/projects/').then((result) => 
        { 
          if(result.data.status == "true")
          {
            if(result.data.member_projects !="")
            {
              for (var i = 0; i < result.data.member_projects.length; i++)
              { 
                var options =  { value: result.data.member_projects[i].id_project, label: result.data.member_projects[i].name }
                this.MultiSelectProject.options.push(options)
              }
            }
          }
        });
      },
      DeleteTimeSheet(id) 
      {
        Api.get('/timesheet/delete/'+id+'').then((result) => 
        {
          if(result.data.status == "true")
          {
            $('#timesheet-table').DataTable().destroy()
            this.GetTimeSheet()
          }
        })
      },
      ActiveToInput()
      {
        if (this.TimeSheetPost.from !="") { this.Form.TimeSheetTo = false }
        if (this.TimeSheetPost.from ==null) { this.TimeSheetPost.to = ''; this.Form.TimeSheetTo = true }
      }
    },
    mounted()
    {
      this.GetTimeSheet()
      this.GetMemberProjects()
    }
  }
</script>

<style scoped></style>
